import React, { useCallback, useState } from 'react';
import { Button, Checkbox, Form, Input, Modal, Select, Space, Switch, TimePicker, Upload, message, notification } from 'antd';
import type { UploadProps } from 'antd';
import Papa from 'papaparse';
import useApi from 'hooks/useApi';
import { createDriverSegment, getLayersList } from 'services/message.service';
import { useNavigate } from 'react-router-dom';
import useModal from 'hooks/useModal';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import dayjs from 'dayjs';

const { RangePicker } = TimePicker;
const { Option } = Select;
const CreateDriverSehment: React.FC = () => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<any[]>([]);
    const [csvData, setCsvData] = useState<any[]>([]);
    const navigate = useNavigate();
    const confirmModal = useModal();
    const [selectedDays, setSelectedDays] = useState<CheckboxValueType[]>([]);
    const [serviceType, setServiceType] = useState<string[]>([]);
    const [status, setStatus] = useState<boolean>(true);
    const [layer, setLayer] = useState<string | null>(null);
    const [optionData, setOptionData] = useState([{ value: '', label: 'All Layers' }]);
    const [idOptionData, setIdOptionData] = useState([{ value: '', label: 'All Layers ID' }]);
    const [selectedLayerName, setSelectedLayerName] = useState('');
    
    const uploadProps: UploadProps = {
        accept: '.csv',
        beforeUpload: (file) => {
            const isCsv = file.type === 'text/csv';
            if (!isCsv) {
                message.error('Only CSV files are allowed!');
                return Upload.LIST_IGNORE;
            }
            setFileList([file]); // Store file
            parseCSV(file); // Parse file
            return false; // Prevent auto upload
        },
        onRemove: () => {
            setFileList([]);
            setCsvData([]);
        },
        fileList,
    };

    // Function to parse CSV
    const parseCSV = (file: File) => {
        const reader = new FileReader();
        reader.onload = ({ target }) => {
            if (target?.result) {
                Papa.parse(target.result as string, {
                    header: false,
                    skipEmptyLines: true,
                    complete: (result) => {
                        setCsvData(result.data);
                        console.log('Parsed CSV Data:', result.data);
                    },
                });
            }
        };
        reader.readAsText(file);
    };
    const { request: getLayersRequest, loading: fetchingLayers } = useApi({
        api: getLayersList,
    });
    const fetchLayers = useCallback(
        async (searchKey: '') => {
            try {
                const result = await getLayersRequest({
                    page_size: 100,
                    page: 1,
                    search_by: 'name',
                    search_key: searchKey,
                });
                let d = result.data;
                let layer = d.layers.map((c: any) => {
                    return {
                        ...c,
                        key: c.id,
                    };
                });
                let options = [{ value: '', label: 'All' }];
                let idOptions = [{ value: '', label: 'All' }];

                layer.forEach(function (d: any) {
                    options.push({
                        value: d.name,
                        label: d.name,
                    });
                    idOptions.push({
                        value: d.id,
                        label: d.id,
                    });
                });
                setOptionData(options);
                setIdOptionData(idOptions);
            } catch (error) {
                throw error;
            }
        },
        [getLayersRequest],
    );

    const handleSearchLayer = useCallback(
        (value: any) => {
            if (value != '') {
                fetchLayers(value);
            }
        },
        [fetchLayers],
    );
    const {
        request: createDriverSegmentRequest,
        loading: creatingDriverSegment,
    } = useApi({
        api: createDriverSegment,
    });

    const handleSubmit = async () => {
        const values = form.getFieldsValue(); // Get form data
        const payload = {
            name: values.name,
            description: values.description,
            current_size: 0,
            rules: [],  // valid json
            status: 'INACTIVE',
        };

        try {
            const res = await createDriverSegmentRequest(payload);

            if (!res.error) {
                notification.success({
                    message: 'Success',
                    description: 'Successfully generated.',
                    duration: 3,
                    onClose: () => navigate('/driver-segments')
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: res.error.message,
                    duration: 3,
                });
            }
        } catch (error) {
            console.error('API Error:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to submit data.',
                duration: 3,
            });
        }
    };

    const handleAddServiceArea = (values: any) => {
        console.log("Form Values:", {
            selectedDays,
            serviceType,
            status,
            layer,
            timeRange: values.timeRange || null,
        });
    };


    return (
        <div className='flex justify-center'>
            <div className='w-2/5'>
                <div>
                    <a>Go Back</a>
                </div>
                <div className='border border-solid rounded-xl border-[#E7F0F3] p-8'>
                    <h5 className='border border-solid border-b-[#E7F0F3] border-t-0 border-l-0 border-r-0'>Driver Segment</h5>
                    <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" onFinish={handleSubmit}>
                        <Form.Item name="name" label="Segment Name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="csvFile" label="Upload CSV File">
                            <Upload {...uploadProps}>
                                <Button type="primary">Click to Upload CSV</Button>
                            </Upload>
                        </Form.Item>
                        <div className='flex justify-between'>
                            <div>
                                <h5>Driver Service Area</h5>
                            </div>
                            <div>
                                <button onClick={() => confirmModal.show()} className='bg-transparent border-0 text-[#0084B0] text-sm'>+ Add</button>
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            <Form.Item>
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={creatingDriverSegment}>
                                        Submit
                                    </Button>
                                    <Button htmlType="reset">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
            <Modal
                {...confirmModal}
                title="Add Driver Service Area"
                footer={null}
            >
                <div className='p-2'>
                    <Form layout="vertical" onFinish={handleSubmit}>
                        <Form.Item label="Select Day(s)">
                            <Checkbox.Group
                                options={["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]}
                                value={selectedDays}
                                onChange={setSelectedDays}
                            />
                        </Form.Item>

                        <Form.Item label="Select Time" name="timeRange">
                            <RangePicker format="HH:mm" />
                        </Form.Item>

                        <Form.Item label="Service Type">
                            <Space wrap>
                                {["Passenger 2-Wheel", "Padala", "Passenger 4-Wheel"].map(type => (
                                    <Button
                                        key={type}
                                        type={serviceType.includes(type) ? "primary" : "default"}
                                        onClick={() =>
                                            setServiceType(prev =>
                                                prev.includes(type)
                                                    ? prev.filter(t => t !== type)
                                                    : [...prev, type]
                                            )
                                        }
                                    >
                                        {type}
                                    </Button>
                                ))}
                            </Space>
                        </Form.Item>

                        <Form.Item label="Status">
                            <Switch checked={status} onChange={setStatus} />
                        </Form.Item>

                        <Form.Item label="Layer">
                            <Select
                                placeholder="Select Layer"
                                allowClear
                                showSearch
                                onChange={setLayer}
                                options={optionData}
                                defaultValue={optionData[0].value}
                                value={selectedLayerName}
                            >
                            </Select>
                        </Form.Item>

                        <div className='flex justify-end'>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="reset">Cancel</Button>
                                    <Button onClick={handleAddServiceArea} type="primary" htmlType="submit">Save</Button>
                                </Space>
                            </Form.Item>
                        </div>

                    </Form>
                </div>
            </Modal>
        </div>

    );
};

export default CreateDriverSehment;
