import Modal from 'antd/lib/modal/Modal';
import { CustomNotification } from 'components/basic';
import useApi from 'hooks/useApi';
import useMount from 'hooks/useMount';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSegmentById, deleteSegment } from 'services/message.service';
interface DeleteSegmentModalProps {
    modal: any;
    onSuccess?: () => void;
    segmentId?: any;
    name?: any;
}

const DeleteSegmentModal: React.FC<DeleteSegmentModalProps> = ({ modal, onSuccess, segmentId, name }) => {
    let { id: paramId } = useParams();
    const [segmentName, setSegmentName] = useState('');
    const idToDelete = segmentId ?? paramId;
    const { request } = useApi({
        api: getSegmentById,
    });
    useMount(() => {
        if (!segmentId) {
            fetchSegmentById(segmentId + '');
        }
    });
    const fetchSegmentById = useCallback(
        async (segmentId: string) => {
            try {
                if (segmentId === 'null') return;
                const response = await request({ id: segmentId });
                setSegmentName(response?.data?.name);
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const handleOnClose = () => {
        modal.close();
    };

    const { request: deleteRequest, loading } = useApi({
        api: deleteSegment,
    });

    const deleteSegments = async (id: any) => {
        const res = await deleteRequest({
            id,
        });
        if (!res?.error) {
            CustomNotification({
                type: 'success',
                message: 'Success',
                description: <p>{segmentName ? segmentName : name} Segment deleted</p>,
            });
            onSuccess?.();
            handleOnClose();
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: res?.error?.message,
            });
        }
    };

    const handleFormFinish = async () => {
        if (idToDelete) {
            deleteSegments(idToDelete);
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: 'No valid ID provided for deletion.',
            });
        }
    };

    return (
        <Modal
            {...modal}
            onCancel={handleOnClose}
            title="Delete Driver Segment"
            onOk={handleFormFinish}
            okText="Delete"
            okButtonProps={{
                danger: true,
                loading: loading,
                disabled: loading,
            }}
            cancelButtonProps={{
                disabled: loading,
            }}
            closeIcon={false}
            closable={false}
            maskClosable={false}
            escapeKey={false}
        >
            <p>
                Are you sure you want to delete the segment "<b>{segmentName ? segmentName : name}</b>" ? This action cannot
                be undone.
            </p>
        </Modal>
    );
};

export default DeleteSegmentModal;
