import React, { useCallback, useContext, useState } from 'react';
import { Tabs, Skeleton } from 'antd/lib';
import { Card } from 'components';
import { getSegmentById} from 'services/message.service';
import useMount from 'hooks/useMount';
import { useParams } from 'react-router-dom';
import Text from 'components/basic/Typography/Text';
import useApi from 'hooks/useApi';
import DetailContentLayout from 'components/layouts/ContentLayout/DetailContentLayout';
import DriverSegmentSummary from './DriverSegmentSummary';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { useNavigate } from 'react-router-dom';
import Drivers from './Drivers';

const DriverSegmentDetail = ({ page = 'overview' }) => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(page);
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.VIEW) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };

    let { id } = useParams();

    useMount(() => {
        fetchSegmentById(id + '');
    });

    const { request, result, loading } = useApi({
        api: getSegmentById,
    });


    const fetchSegmentById = useCallback(
        async (segmentId: string) => {
            try {
                await request({ id: segmentId || id });
            } catch (error) {
                throw error;
            }
        },
        [request, id],
    );

    if (loading) {
        return (
            <Card>
                <Skeleton />
            </Card>
        );
    }

    const data = result?.data || {};
    
    return (
        <DetailContentLayout fallbackRoute='/driver-segments'>
            <div className='mt-4'>
                <div className='rounded border border-dashed border-slate-200 flex flex-col gap-2 w-full h-auto p-sm mb-4'>
                    <DriverSegmentSummary data={[data , id]} page="driver-segments" refreshData={() => request({ id })} />
                </div>
                <div>

                    <Tabs
                        defaultActiveKey={currentPage}
                        className="gap-2"
                        tabBarGutter={50}
                        onChange={e => {
                            navigate(`/driver-segments/${id}/${e}`);
                            setCurrentPage(e);
                        }}
                        items={[
                            {
                                label: (
                                    <div className="flex flex-row gap-0 items-center">
                                        <Text className="uppercase">DRIVERS</Text>
                                    </div>
                                ),
                                key: 'drivers',
                                children: (
                                    <Drivers tableData = {data?.data?.driver_list}/>
                                ),
                            },
                            {
                                label: (
                                    <div className="flex flex-row gap-0 items-center">
                                        <Text className="uppercase">DRIVER SERVICE AREA</Text>
                                    </div>
                                ),
                                key: 'trip-history',
                                disabled: !hasActionAccess(PagesEnum.TRIPS),
                                // children: <DriverTripHistory id={id} />,
                            },


                        ]}
                    />
                </div>
            </div>
        </DetailContentLayout>
    );
};

export default DriverSegmentDetail;
