import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Tag } from 'antd/lib';
import { Table } from 'components';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Text } from 'components/basic';
import { DownOutlined } from '@ant-design/icons';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import classnames from 'clsx';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { AuthUserContext } from 'components/context/AuthUserContext';

const Drivers = ({ tableData = { drivers: [] } }: any) => {
    const userRole = useContext(AuthUserContext);

    const [data, setData] = useState([]);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });

    const navigate = useNavigate();

    const hasActionAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };

    useEffect(() => {
        if (tableData?.drivers) {
            setData(tableData.drivers.map((driver: { id: any }) => ({ ...driver, key: driver.id })));
            let pagination = tableData.pagination;
            let p = {
                ...pagination,
                current: pagination.page,
                defaultCurrent: 1,
                pageSize: pagination.page_size,
                total: pagination.total_count,
            };
            setPagination(p);
        }
    }, [tableData]);

    const ColumnActions = useCallback(
        (user: any) => {
            return (
                <Dropdown
                    menu={{
                        items: [
                            {
                                label: 'View',
                                key: '1',
                                onClick: () => {
                                    navigate(`/drivers/${user.id}/overview`);

                                },
                            }
                        ],
                    }}
                >
                    <Button type="ghost">
                        <DownOutlined />
                    </Button>
                </Dropdown>
            );
        },
        [navigate]
    );

    const columns = [
        {
            title: 'Driver ID',
            render: (user: any) => (
                <div className="flex items-center">
                    <a href={`/drivers/${user.id}/overview`} target="_blank" rel="noopener noreferrer">
                        {user.public_id}
                    </a>
                </div>
            ),
        },
        {
            title: 'Driver',
            key: 'name',
            render: (user: any) => (
                <Text className={classnames({ 'text-gray-500': !user.first_name })}>
                    {user.first_name || '-'} {user.last_name || '-'}
                </Text>
            ),
        },
        {
            title: 'Mobile Number',
            dataIndex: 'phone_number',
            key: 'phone_number',
        },
        {
            title: 'Status',
            render: (user: any) => (
                <div className="flex items-start flex-col">
                    {user.account_status == 'ACTIVE' && <Tag color="green">Active</Tag>}
                    {user.account_status == 'DEACTIVATED' && <Tag color="red">Deactivated</Tag>}
                    {user.account_status == 'SUSPENDED' && <Tag color="orange">Suspended</Tag>}
                    {user.account_status == 'BANNED' && <Tag>Banned</Tag>}
                    {user.account_status == 'SHADOW_BANNED' && <Tag>Shadow Banned</Tag>}
                    {user.account_status == 'SHADOW_SUSPENDED' && <Tag color="orange">Shadow Suspended</Tag>}
                </div>
            ),
        },
        {
            title: 'License Number',
            dataIndex: 'license_number',
            key: 'license_number',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '',
            className: 'action-cell',
            render: (user: any) => ColumnActions(user),
        },
    ];

    return (
        <ListLayout
            actionComponent={
                <div className="flex">
                    {hasActionAccess(PagesEnum.BIKER_ACTIVATION) && (
                        <Button
                            type="primary"
                            onClick={() => {
                                console.log("exported")
                            }}
                        >
                           Export Drivers
                        </Button>
                    )}
                </div>
            }
        >
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total: any) => `${total?.toLocaleString()} items`,
                }}
            />
        </ListLayout>
    );
};

export default Drivers;
